import React from 'react'
import { Box } from '~/components/box'
import { currency } from '~/utils/format'
import { CheckBadgeIcon, PlusCircleIcon } from '@heroicons/react/16/solid'
import type { Results } from '~/domain/calc/products.server'
import { cn } from '~/utils/cn'

type Props = {
  results: Results
}

function ProductResults({ results }: Props) {
  return (
    <div className="grid auto-cols-fr grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
      {Object.entries(results.products).map(([key, product]) => (
        <Box
          color="success"
          spacing="medium"
          key={key}
          className="relative overflow-hidden"
        >
          {product.special && (
            <div className="absolute right-0 top-0 h-16 w-16">
              <div className="absolute right-[-35px] top-[32px] w-[170px] rotate-45 transform bg-accent py-1 text-center text-xs font-semibold text-accent-content">
                doporučujeme
              </div>
            </div>
          )}
          <h3 className={cn('text-2xl font-bold', product.special && '!mt-0')}>
            {product.title}
          </h3>
          <div>
            <div className="badge badge-outline">až</div>
            <div className="whitespace-nowrap text-center text-4xl font-semibold text-accent sm:text-3xl">
              {currency(product.potentialOffer)}
            </div>
          </div>
          {product.projectDocumentationExtraCzk > 0 && (
            <div className="flex items-center">
              <PlusCircleIcon className="my-1 mr-2 h-4 w-4 flex-shrink-0 text-neutral" />
              {currency(product.projectDocumentationExtraCzk)} na projektovou
              dokumentaci
            </div>
          )}
          {product.bulletPoints && (
            <div>
              {product.bulletPoints.map((item, key) => (
                <div key={key}>
                  <div className="flex items-start">
                    <CheckBadgeIcon className="my-1 mr-2 h-4 w-4 flex-shrink-0 text-accent" />
                    <p>{item}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </Box>
      ))}
    </div>
  )
}

export default ProductResults
